import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import {Grid, Card,Container, CardContent, Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import Layout from "../components/layout"
import SEO from "../components/seo"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  itemCardRoot: {
    [theme.breakpoints.down("xs")]:
    {
      display: "flex",
      justifyContent: "center"
    }
  },
  media: {
    height: 140,
  },
  lineText: {
    textAlign: 'center',
    marginBottom: '26px',
    marginTop: '26px',
    position: 'relative',
    fontSize: '1.2135em',
    textTransform: 'uppercase',    

    '&::before': {
      content: '" "',
      width: '100%',
      height: '3px',
      position: 'absolute',
      backgroundColor: '#ddd',
      left: '0',
      top: '50%',
      zIndex: '1',
      },
  },
  lineTextSpan: {
    position: 'relative',
    backgroundColor: '#fff',
    display: 'inline-block',
    padding: '0 15px',
    zIndex: '2',
  }
}));

const BlogIndex = ({ data, location }) => {
  const classes = useStyles();
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

/*<h2 className={classes.lineText}>
        <span className={classes.lineTextSpan}>Angesagte Beiträge</span>
      </h2>
      */

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Elektrotechnik und Software Blog" />
      <Container component="p">
        Hi, 
        <br/>
        schön das du bei uns gelandet bist. Wir haben unsere Seite komplett neu gestaltet. Jetzt können wir dir noch schneller neue Inhalte und spannende Projekt zur Verfügung stellen. Derzeit arbeiten wir an vielen neuen Funktionen wie einer Wunschbox! &#128525; 
        <br/>
        <br/>
        Bleib gespannt und viel Spass beim Stöbern!
      </Container>

      <h1 className={classes.lineText}>
        <span className={classes.lineTextSpan}>Neuste Beiträge auf Redlabs</span>
      </h1>
      <Grid container spacing={3}>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug

        return (
          <Grid key={node.fields.slug} item sm={6} xs={12} md={3} className={classes.itemCardRoot}>
            
            <Link style={{ boxShadow: `none`, textDecoration: 'none' }} to={node.fields.slug} >
            <Card className={classes.root} style={{height: '100%'}} >
                { node.frontmatter.coverImage && (
                  <Image description={title} alt={title} fluid={node.frontmatter.coverImage.childImageSharp.fluid} sizes={{ ...node.frontmatter.coverImage.childImageSharp.fluid, aspectRatio: 4/3 }} />
                )}
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                    {title}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" component="p">
                      {node.frontmatter.description || node.excerpt}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
          </Grid>
        )
      })}
      </Grid>
    </Layout>
  )
}

export default BlogIndex

// use allBlogposts instead of  filter: {fileAbsolutePath: {regex: "/blog/"}}   , frontmatter: {publish: {eq: true}}

export const pageQuery = graphql`
{
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, filter: {fileAbsolutePath: {regex: "/blog/"}, frontmatter: {publish: {eq: true}}}) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD.MM.YYYY")
          title
          description
          publish
          coverImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                src
                srcSet
                aspectRatio
                sizes
                base64
              }
            }
          }
        }
      }
    }
  }
}
`


/*
 query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            title
            description
            coverImage{
                childImageSharp{
                  fluid (maxWidth:500, quality:50){
                    src,
                    srcSet
                    aspectRatio
                    sizes
                    base64
                  }
                }
                publicURL
              }
          }
        }
      }
    }
  }
*/